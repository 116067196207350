import React, { useState } from "react";

const ImportBox = props => {
    const itemClass = "import";
    const defaultCatalog = JSON.parse(localStorage.getItem("catalog"));
    const { handleClose } = props;
    const [ globalError, setGlobalError ] = useState(null);
    const [ catalogId, setCatalogId ] = useState(Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : null);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className={ "overbox " + itemClass }>
                    {
                        globalError !== null
                        && <p className="error">{ globalError }</p>
                    }

                </div>
            </div>
        </div>
    )
}

export default ImportBox;
